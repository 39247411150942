import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userEmail: "",
    snackbar_token: undefined,
    routeToFirstMenu: undefined,
  },
  getters: {
    get_user_email: (state) => {
      return state.userEmail;
    },

    get_snackbar_token: (state) => {
      return state.snackbar_token;
    },

    get_route_to_first_menu: (state) => {
      return state.routeToFirstMenu;
    },
  },
  mutations: {
    SET_USER_EMAIL(state, email) {
      state.userEmail = email;
    },

    SET_SNACKBAR_TOKEN(state, snackbarToken) {
      state.snackbar_token = snackbarToken;
    },

    SET_ROUTE_TO_FIRST_MENU(state, route_to_first_menu) {
      state.routeToFirstMenu = route_to_first_menu;
    },
  },
  plugins: [createPersistedState()],
  actions: {},
  modules: {},
});
