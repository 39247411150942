import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: () => import("@/views/MainPages/LoginPage.vue"),
  },
  {
    path: "/landingPage",
    name: "LandingPage",
    component: () => import("@/views/MainPages/LandingPage.vue"),
    children: [
      {
        path: "/myqrlists",
        name: "All QR's",
        component: () => import("@/views/ChildPages/MyQr.vue"),
      },
      {
        path: "/categories",
        name: "Categories",
        component: () => import("@/views/ChildPages/QrCategories.vue"),
      },
      {
        path: "/customcategories",
        name: "Custom Categories",
        component: () => import("@/views/ChildPages/CustomCategories.vue"),
      },
      {
        path: "/users",
        name: "Users",
        component: () => import("@/views/ChildPages/AllUsers.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
